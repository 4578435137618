/* External dependencies */
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import Footer from '@components/website/footer/Footer';
import ArticleDetails from '@entities/articles/ui/article-detail';
import ArticleHeader from '@entities/articles/ui/articles-header/Articles-header';
import Layout from '@shared/ui/layout/WebLayout';

export default function ArticleDetail({ data }) {
  const {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
  } = data || '';

  const {
    contents,
    category,
    createDate,
    heroMedia,
    title,
    slug,
    readTimeMinute,
  } = data.article || '';

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
        <meta name="description" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content={title} />
        <meta
          name="og:image"
          content={`${heroMedia?.imageUrl?.url}`}
          data-rh={true}
        />
        <meta
          name="image"
          property="og:image"
          content={`${heroMedia?.imageUrl?.url}`}
        />
        <meta
          name="twitter:image"
          content={`${heroMedia?.imageUrl?.url}`}
          data-rh={true}
        />
      </Helmet>
      <ArticleHeader />
      <ArticleDetails
        createDate={createDate}
        heroVideo={heroMedia?.fullVideoUrl}
        category={category}
        contents={contents}
        title={title}
        readTimeMinute={readTimeMinute}
      />
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query ($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
    article(id: { eq: $id }) {
      articleType
      category
      createDate
      slug
      heroMedia {
        fullVideoUrl
        imageUrl {
          url
        }
      }
      contents {
        data
      }
      id
      synopsis
      title
      readTimeMinute
    }
  }
`;
