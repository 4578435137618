/* External dependencies */
import { Container, Div, Text } from 'atomize';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import YouTube from 'react-youtube';

/* Local dependencies */
import { getLabelColor } from '@entities/articles/lib/getLabelColor';
import LabelText from '@entities/articles/ui/card/LabelText';
import DateFormat from '@shared/utils/DateFormat';
import './article-detail.scss';

interface articleProps {
  contents?: any;
  category?: string;
  createDate?: number;
  heroVideo?: any;
  title?: string;
  readTimeMinute: string;
}

export default function ArticleDetails(props: articleProps) {
  const { contents, category, createDate, heroVideo, title, readTimeMinute } =
    props;
  const colorCategory = getLabelColor(category);
  const articleTextContent = contents ? contents[0]?.data || [] : [];
  const videoUrl = heroVideo?.substring(
    heroVideo.lastIndexOf('/') + 1,
    heroVideo.length,
  );

  const { isBanner } = useSelector((state) => state?.banner);
  const { t } = useTranslation();

  return (
    <Container>
      <Div p={{ t: { xs: '114px', lg: isBanner ? '200px' : '114px' } }}>
        <Div m={{ b: '35px' }}>
          <Link to={`/articles/${category?.toLowerCase()}`}>
            <LabelText color={colorCategory} text={category} />
          </Link>
          <Div m={{ b: '16px' }} textColor="grayTextInCard">
            <Text
              tag="span"
              textSize={{ xs: 'body', md: 'display1', lg: 'display2' }}
              textWeight="400"
              m={{ t: '8px' }}
              d="flex"
              justify="start"
            >
              <DateFormat date={createDate} />
              <Text tag="span" className="article-author">
                {t('minuteRead', {
                  minute: readTimeMinute,
                })}
              </Text>
              <Text tag="span" className="article-author">
                Айжан Сагынова
              </Text>
            </Text>
          </Div>
          <Div
            d="flex"
            flexDir="row"
            m={{ x: { xs: 'auto' } }}
            className="article"
          >
            <Div className="article-title">
              <ReactMarkdown children={articleTextContent} />
            </Div>
          </Div>
        </Div>
        <Div
          className="article"
          d="flex"
          flexDir="row"
          m={{ x: { xs: 'auto' } }}
        >
          <Div className="article-synopsis" textColor="text_color_for_teaser">
            <ReactMarkdown children={articleTextContent} />
          </Div>
        </Div>
        {videoUrl && (
          <Div
            className="article-img"
            w="100%"
            m={{ b: '32px' }}
            rounded="20px"
          >
            <YouTube
              videoId={videoUrl}
              containerClassName="article-img"
              title={title}
            />
          </Div>
        )}
        <Div className="article">
          <Div
            className="article-content"
            m={{ b: '45px' }}
            textColor="text_color_for_teaser"
          >
            <ReactMarkdown children={articleTextContent} />
          </Div>
        </Div>
      </Div>
    </Container>
  );
}
